@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*------Color variables------*/
    --black_900: #0c0a08;
    --black_900_01: #060609;
    --black_900_02: #0e0e0e;
    --black_900_03: #000000;
    --black_900_19: #00000019;
    --blue_400: #519ef9;
    --blue_gray_100: #d9d9d9;
    --blue_gray_100_01: #ccd3d5;
    --blue_gray_200: #b0b0bd;
    --blue_gray_200_01: #b5bbcb;
    --blue_gray_200_02: #b6bbcc;
    --blue_gray_200_03: #b8bfc9;
    --blue_gray_200_04: #aaafbb;
    --blue_gray_300: #93a4c4;
    --blue_gray_400: #7a85ab;
    --blue_gray_400_01: #80808d;
    --blue_gray_400_02: #878787;
    --blue_gray_500: #697397;
    --blue_gray_500_01: #707d98;
    --blue_gray_600: #4f617a;
    --blue_gray_600_01: #545d7e;
    --blue_gray_700: #4d5776;
    --blue_gray_700_01: #554856;
    --blue_gray_700_02: #554956;
    --blue_gray_700_03: #404b5e;
    --blue_gray_700_04: #564a57;
    --blue_gray_700_05: #564956;
    --blue_gray_700_06: #564957;
    --blue_gray_800: #38404f;
    --blue_gray_800_01: #3b4651;
    --blue_gray_800_02: #333a49;
    --blue_gray_800_03: #354559;
    --blue_gray_900: #252d3f;
    --blue_gray_900_01: #293447;
    --blue_gray_900_02: #3c2b3d;
    --blue_gray_900_03: #2f3d40;
    --blue_gray_900_04: #1f2733;
    --blue_gray_900_05: #203133;
    --gray_100: #f6f6f6;
    --gray_200: #ececec;
    --gray_200_01: #efefef;
    --gray_200_02: #e7e7e7;
    --gray_300: #d9d9e6;
    --gray_300_01: #e3e3e3;
    --gray_300_02: #e4e5e9;
    --gray_300_03: #dcdcdc;
    --gray_400: #c2c2c2;
    --gray_400_01: #c4c4c4;
    --gray_400_02: #b9b9b9;
    --gray_400_03: #bfbfbf;
    --gray_400_04: #bababa;
    --gray_400_05: #c8c8c8;
    --gray_50: #fcfcfc;
    --gray_500: #9a9aa4;
    --gray_500_01: #9e9e9e;
    --gray_500_02: #93989a;
    --gray_600: #6e656e;
    --gray_600_01: #726b73;
    --gray_600_02: #6e666e;
    --gray_600_03: #6e666f;
    --gray_600_04: #726a72;
    --gray_600_05: #6a6e6f;
    --gray_600_06: #717272;
    --gray_600_07: #828282;
    --gray_600_08: #716971;
    --gray_600_09: #6f676f;
    --gray_600_10: #706870;
    --gray_600_11: #736c73;
    --gray_600_12: #925d66;
    --gray_700: #665c66;
    --gray_700_01: #635964;
    --gray_700_02: #5b4e5b;
    --gray_700_03: #5d515d;
    --gray_700_04: #685f69;
    --gray_700_05: #5b4f5b;
    --gray_700_06: #635863;
    --gray_700_07: #615662;
    --gray_700_08: #615661;
    --gray_700_09: #5b4f5c;
    --gray_700_10: #574b58;
    --gray_700_11: #574b57;
    --gray_700_12: #6b626b;
    --gray_700_13: #5f5460;
    --gray_700_14: #6d656e;
    --gray_700_15: #645964;
    --gray_700_16: #69606a;
    --gray_700_17: #574a58;
    --gray_700_18: #574a57;
    --gray_700_19: #605561;
    --gray_700_20: #695f69;
    --gray_700_21: #605560;
    --gray_700_22: #5a4e5b;
    --gray_700_23: #645a65;
    --gray_700_24: #6d646d;
    --gray_700_25: #5d525e;
    --gray_700_26: #605661;
    --gray_700_27: #5a4f5b;
    --gray_700_28: #594d59;
    --gray_700_29: #696069;
    --gray_700_30: #625762;
    --gray_700_31: #625763;
    --gray_700_32: #655a65;
    --gray_700_33: #594d5a;
    --gray_700_34: #5c505c;
    --gray_700_35: #5c505d;
    --gray_700_36: #5e535f;
    --gray_700_37: #675d67;
    --gray_700_38: #6a616b;
    --gray_700_39: #6a616a;
    --gray_700_40: #594c59;
    --gray_700_41: #625863;
    --gray_700_42: #5c515d;
    --gray_700_43: #605460;
    --gray_700_44: #5a4d5a;
    --gray_700_45: #5e525e;
    --gray_700_46: #6a606a;
    --gray_700_47: #584c59;
    --gray_700_48: #584c58;
    --gray_700_49: #5f535f;
    --gray_700_50: #6c636c;
    --gray_700_51: #615762;
    --gray_700_52: #5b505c;
    --gray_700_53: #655b65;
    --gray_700_54: #655b66;
    --gray_700_55: #584b58;
    --gray_700_56: #685e68;
    --gray_800: #554755;
    --gray_800_01: #493a49;
    --gray_800_02: #463747;
    --gray_800_03: #4c3e4d;
    --gray_800_04: #493b4a;
    --gray_800_05: #524553;
    --gray_800_06: #524552;
    --gray_800_07: #4a3b4b;
    --gray_800_08: #4f404f;
    --gray_800_09: #4a3b4a;
    --gray_800_10: #443444;
    --gray_800_11: #443445;
    --gray_800_12: #413142;
    --gray_800_13: #504251;
    --gray_800_14: #343b41;
    --gray_800_15: #4f414f;
    --gray_800_16: #4f4150;
    --gray_800_17: #4d3e4d;
    --gray_800_18: #402f41;
    --gray_800_19: #3d2c3e;
    --gray_800_20: #483949;
    --gray_800_21: #534553;
    --gray_800_22: #483948;
    --gray_800_23: #63350f;
    --gray_800_24: #4f4250;
    --gray_800_25: #463647;
    --gray_800_26: #3f2f40;
    --gray_800_27: #493a4a;
    --gray_800_28: #4d3f4e;
    --gray_800_29: #4d3f4d;
    --gray_800_30: #433344;
    --gray_800_31: #4a3c4b;
    --gray_800_32: #534654;
    --gray_800_33: #534653;
    --gray_800_34: #504351;
    --gray_800_35: #3f2e3f;
    --gray_800_36: #403041;
    --gray_800_37: #3f2e40;
    --gray_800_38: #603603;
    --gray_800_39: #4b3d4c;
    --gray_800_40: #514452;
    --gray_800_41: #453646;
    --gray_800_42: #534754;
    --gray_800_43: #544654;
    --gray_800_44: #4e3f4e;
    --gray_800_45: #4b3c4c;
    --gray_800_46: #514352;
    --gray_800_47: #453546;
    --gray_800_48: #423243;
    --gray_800_49: #544754;
    --gray_800_50: #544755;
    --gray_800_51: #3e2e3f;
    --gray_800_52: #4e404e;
    --gray_800_53: #4e404f;
    --gray_800_54: #544855;
    --gray_800_55: #3e2d3f;
    --gray_800_56: #473848;
    --gray_800_57: #524452;
    --gray_800_58: #4e414f;
    --gray_800_59: #443545;
    --gray_800_60: #702f0a;
    --gray_900: #15181a;
    --gray_900_01: #4c2c0d;
    --gray_900_02: #212627;
    --gray_900_03: #161b1c;
    --gray_900_04: #1c1c1c;
    --indigo_200: #92a1cb;
    --indigo_200_01: #abbee4;
    --indigo_50: #e4e4f1;
    --light_green_700: #818048;
    --lime_900: #99603c;
    --lime_900_01: #754410;
    --white_a700: #ffffff;
    --yellow_700: #f5b82c;
    --yellow_700_01: #f1c53d;

    /*------Shadow variables------*/
    --shadow-xs: 0 4px 11px 0 #00000019;

    /*------Border radius variables------*/
    --radius-xs: 4px;
    --radius-sm: 10px;
    --radius-md: 20px;
    --radius-lg: 26px;
    --radius-xl: 30px;
    --radius-2xl: 34px;
    --radius-3xl: 40px;
    --radius-4xl: 46px;
    --radius-5xl: 68px;

    /*------Spacing variables------*/
    --space-xs: 10px;
    --space-sm: 14px;
    --space-md: 16px;
    --space-lg: 26px;
    --space-xl: 30px;
    --space-2xl: 40px;
    --space-3xl: 80px;
  }
  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    appearance: none;
    background-color: transparent;
    border-color: unset;
    border-width: 0;
    border-radius: unset;
    padding: unset;
    font-size: unset;
    line-height: unset;
  }

  /* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="search"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: transparent;
    border-color: transparent;
  }
  [type="radio"]:focus,
  [type="checkbox"]:focus {
    --tw-ring-color: transparent;
  }
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1394px];
  }
}
